// extracted by mini-css-extract-plugin
export var banner = "new-slider-module--banner--NLKM1";
export var button = "new-slider-module--button--F87MX";
export var container = "new-slider-module--container--QAEix";
export var dot = "new-slider-module--dot--a7Lts";
export var dots = "new-slider-module--dots--5Z2yw";
export var next = "new-slider-module--next--o0y41";
export var noNavButtons = "new-slider-module--no-nav-buttons--52qmE";
export var prev = "new-slider-module--prev--YhIRs";
export var product = "new-slider-module--product--PQ5-L";
export var slider = "new-slider-module--slider--6LkL2";
export var small = "new-slider-module--small--boD9M";