import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, SwiperOptions } from 'swiper';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import 'swiper/css';
import 'swiper/css/pagination';
import {
    container,
    noNavButtons,
    small,
    slider,
    prev,
    next,
    dots,
    banner,
    product,
    button,
    dot,
} from './new-slider.module.scss';

import ArrowButton from '../atoms/arrow-button';

type TSliderLayout = 'banner' | 'card' | 'product';

export interface ISliderProps {
    children?: React.ReactNode[];
    className?: string;
    slidesCount: number;
    settings: SwiperOptions;
    isSmall?: boolean;
    showButtons?: boolean;
    showDots?: boolean;
    layout?: TSliderLayout;
    onChange?(isChanging: boolean): void;
}

SwiperCore.use([Pagination]);

const NewSlider: React.FC<ISliderProps> = ({
    children,
    className = '',
    slidesCount,
    settings,
    isSmall = false,
    showButtons = true,
    showDots = false,
    layout = 'card',
    onChange = () => {},
}) => {
    const { t } = useI18next();
    const [isChanging, setIsChanging] = useState<boolean>(false);
    const [isSlider, setIsSlider] = useState<boolean>(false);
    const swiperRef = useRef<SwiperCore | null>(null);
    const layoutClass = layoutClasses[layout];
    const dotsRef = useRef<HTMLDivElement>(null);

    const [sliderOptions, setSliderOptions] = useState<SwiperOptions>({
        ...settings,
        centerInsufficientSlides: true,
    });

    const getSlide = (isNextSlide = true): void => {
        isNextSlide ? swiperRef.current?.slideNext() : swiperRef.current?.slidePrev();
    };

    const setOptionsBasedOnSlidesPerView = () => {
        const slidesPerView = swiperRef?.current?.params.slidesPerView || 1;
        const isLooped = settings?.loop && slidesCount > slidesPerView;

        setSliderOptions({ ...settings, loop: isLooped });
        setIsSlider(slidesCount > slidesPerView);
    };

    const handleOnChange = () => onChange(true);
    const handleOnInit = () => setOptionsBasedOnSlidesPerView();
    const handleOnResize = () => setOptionsBasedOnSlidesPerView();
    const handleOnSlideChangeTransitionStart = () => setIsChanging(true);
    const handleOnSlideChangeTransitionEnd = () => setIsChanging(false);
    const handleOnBeforeInit = (swiper: SwiperCore) => (swiperRef.current = swiper);

    const pagination = {
        clickable: true,
        el: dotsRef.current,
        renderBullet: function (index: number, className: string) {
            return `
                <button
                    class="${className} ${button}"
                    aria-label="Go to slide number ${index}"
                    disabled="${isChanging}"
                >
                    <span class="${dot}" />
                </button>`;
        },
    };

    return (
        <div
            className={`
                ${container} 
                ${className} 
                ${isSmall ? small : ''} 
                ${isSlider && showButtons ? '' : noNavButtons}
                ${layoutClass}
            `}
        >
            <Swiper
                {...sliderOptions}
                modules={[Autoplay, Pagination]}
                className={slider}
                pagination={pagination}
                onInit={handleOnInit}
                onResize={handleOnResize}
                onChange={handleOnChange}
                onSlideChangeTransitionStart={handleOnSlideChangeTransitionStart}
                onSlideChangeTransitionEnd={handleOnSlideChangeTransitionEnd}
                onBeforeInit={handleOnBeforeInit}
            >
                {children?.map((child) => (
                    <SwiperSlide>{child}</SwiperSlide>
                ))}
            </Swiper>
            {isSlider && showButtons && (
                <>
                    <ArrowButton
                        className={prev}
                        onClick={() => getSlide(false)}
                        ariaLabel={t('button.prev')}
                        disabled={isChanging}
                        typeStyle={layout === 'banner' ? 'blank' : 'normal'}
                    />
                    <ArrowButton
                        className={next}
                        onClick={() => getSlide()}
                        rotateDeg={180}
                        ariaLabel={t('button.next')}
                        disabled={isChanging}
                        typeStyle={layout === 'banner' ? 'blank' : 'normal'}
                    />
                </>
            )}
            {isSlider && showDots && <div ref={dotsRef} className={dots} />}
        </div>
    );
};

const layoutClasses: Record<TSliderLayout, string> = {
    card: '',
    banner: banner,
    product: product,
};

export default NewSlider;
